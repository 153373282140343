import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", {
    attrs: {
      "after-close": _vm.afterModalClose,
      "ok-text": "Add",
      "title": "Add Mapping",
      "width": 520
    },
    on: {
      "ok": _vm.submit
    },
    model: {
      value: _vm.visible,
      callback: function($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_c("a-col", {
    staticClass: "pb-3",
    attrs: {
      "span": 24
    }
  }, [_c("select-input", {
    attrs: {
      "all-in-value": "",
      "label": "Map Type",
      "reference": "grower.common.grade-map-levels",
      "source": "id",
      "source-label": "tableName"
    },
    on: {
      "change": _vm.onMapTypeChange
    }
  })], 1), _c("a-col", {
    staticClass: "pb-3",
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "all-in-value": "",
      "disabled": _vm.disabled,
      "label": "Crop Code",
      "reference": "grower.common.crops",
      "source": "id",
      "source-label": _vm.genCropCodeLabel
    },
    on: {
      "change": _vm.onCropCodeChange
    }
  })], 1), _c("a-col", {
    staticClass: "pb-3",
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "data-source": _vm.mapCodes,
      "disabled": _vm.disabled,
      "label": "Map Code",
      "source": "mapCode",
      "source-label": "description"
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("mapCode", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "data-source": _vm.gradeCodes,
      "disabled": _vm.disabled,
      "label": "Grade Code",
      "source": "id",
      "source-label": "description"
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeId", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "data-source": _vm.bpcsGradeCodes,
      "disabled": _vm.disabled,
      "label": "BPCS Grade Code",
      "source": "id",
      "source-label": "description"
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("bpcsGradeId", $event);
      }
    }
  })], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateGradeMap",
  inject: ["resourceProps"],
  data() {
    const [
      gradeMapProps,
      mapCodeProps,
      gradeProps,
      bpcsGradeProps
    ] = this.resourceProps;
    return {
      bpcsGradeProps,
      cropCode: null,
      gradeMapProps,
      gradeProps,
      mapCodeProps,
      mapLevel: null,
      disabled: true,
      visible: false
    };
  },
  computed: {
    bpcsGradeCodes() {
      return this.bpcsGradeProps.crud.getList();
    },
    gradeCodes() {
      return this.gradeProps.crud.getList();
    },
    mapCodes() {
      return this.mapCodeProps.crud.getList();
    }
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    genCropCodeLabel(option) {
      return `${option.code} - ${option.name}`;
    },
    storeValue(resourceKey, value) {
      this.gradeMapProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    async submit() {
      await this.gradeMapProps.crud.submitEntity("create");
      await this.gradeMapProps.crud.fetchList();
      this.visible = false;
    },
    afterModalClose() {
      this.$router.push(this.gradeMapProps.redirectRoute);
    },
    onCropCodeChange({ code }) {
      this.cropCode = code;
      this.gradeMapProps.crud.updateEntity("createForm", {
        resourceKey: "cropCode",
        value: code
      });
      if (this.mapType) {
        this.mapCodeProps.crud.fetchList(`${this.mapCodeProps.apiUrl}/grower/common/map-codes?mapLevel=${this.mapType}&cropCode=${code}`, void 0, false);
      }
      this.gradeProps.crud.fetchList(`${this.gradeProps.apiUrl}/grower/common/grades-for-grade-map?cropCode=${code}`, void 0, false);
      this.bpcsGradeProps.crud.fetchList(`${this.bpcsGradeProps.apiUrl}/grower/common/bpcs-grades-for-grade-map?cropCode=${code}`, void 0, false);
    },
    onMapTypeChange(value) {
      this.mapType = value.mapLevel;
      this.disabled = false;
      this.storeValue("gradeMapLevelId", value.id);
      if (this.cropCode) {
        this.mapCodeProps.crud.fetchList(`${this.mapCodeProps.apiUrl}/grower/common/map-codes?mapLevel=${value.mapLevel}&cropCode=${this.cropCode}`, void 0, false);
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateGradeMap = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "create-grade-map"
  }, [_c("resource", {
    attrs: {
      "name": "grower.common.crops",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.grade-map-levels",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": ["grower.grade-maps", "grower.common.map-codes", "grower.common.grades-for-grade-map", "grower.common.bpcs-grades-for-grade-map"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/watties-grower/grade-maps"],
      "resource-id-name": ["id", "mapCode", "id", "id"]
    }
  }, [_c("create-grade-map")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateGradeMap
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
